<template>
  <base-modal :modal.sync="value" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div></div>

      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">{{ `${form.id ? "Editar" : "Adicionar novo"} ` + `${typeAgent == `coletor-point` ? "Ponto de coleta" : "Agente de coleta"} `}}</h2>
        </div>

        <div class="modal__form--group">
          <div v-if="typeAgent != `coletor-point`">
            <r-select label="Coletor" v-model="form.collector_id" :items="collectorUsers" :readonly="loadingCollectorUsers" class="col-12" labelName="name" placeholder="Selecionar agente de coleta" />
            <r-select label="Bairro" v-model="form.district_id" :items="neighborhoods" :readonly="loadingNeighborhoods" class="col-12" labelName="name" placeholder="Todos os bairros" noOptions="Todos os bairros" />
            <r-input label="Quantidade mínima" v-model.number="form.minimal_amount" type="number" />
            <r-input label="Valor" v-model="form.value" type="money" />
          </div>
          <div v-else>
            <r-select label="Coletor" v-model="formPoint.collector_id" :items="collectorUsers" :readonly="loadingCollectorUsers" class="col-12" labelName="name" placeholder="Selecionar agente de coleta" />
            <r-input label="Nome do ponto de coleta" v-model="formPoint.point_name" type="string" />
            <r-input label="Valor" v-model="formPoint.value" type="money" />
            <r-input label="CEP" type="string" v-model="formPoint.cep" />
            <r-input :readonly="cepNotFound" label="Endereço" type="string" v-model="formPoint.street" />
            <r-input label="Número" type="string" v-model="formPoint.number"/>
            <r-input label="Complemento" type="string" v-model="formPoint.complement"/>
            <r-input :readonly="cepNotFound" label="Bairro" type="string" v-model="formPoint.district"/>
            <r-input :readonly="cepNotFound" label="Cidade" type="string" v-model="formPoint.city"/>
            <r-input :readonly="cepNotFound" label="Estado" type="string" v-model="formPoint.province"/>
          </div>
          <div class="footer_modal">
            <button type="button" @click.prevent="onSave(), saving = true" class="button_outline">
              {{ "Salvar" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import swal from 'sweetalert'
import axios from 'axios'

const defaultForm = {
  id: null,
  city_company_item_id: null,
  collector_id: null,
  district_id: null,
  value: 0,
  minimal_amount: null,
  status: 1
}

export default {
  props: {
    value: Boolean,
    editing: Object,
    cityId: Number,
    city: String,
    typeAgent: String,
    cityCompanyItem: Number
  },
  components: {
    baseModal: () => import('@/components/modal'),
    RSelect: () => import('@/components/select.vue'),
    RInput: () => import('@/components/input.vue')
  },
  data: () => ({
    cepNotFound: true,
    form: {
      id: null,
      city_company_item_id: null,
      collector_id: null,
      district_id: null,
      value: 0,
      minimal_amount: null,
      status: 1,
      role: null
    },
    formPoint: {
      id: null,
      city_company_item_id: null,
      collector_id: null,
      value: 0,
      minimal_amount: null,
      status: 1,
      role: null,
      cep: null,
      point_name: null,
      street: null,
      number: null,
      complement: null,
      district: null,
      city: null,
      city_id: null,
      id_neighborhood: null,
      province: null,
      province_id: null,
      country_id: null
    },
    saving: false,
    collectorUsers: [],
    loadingCollectorUsers: true,
    neighborhoods: [],
    loadingNeighborhoods: true
  }),
  async mounted () {
    await this.getCollectorUsers()
    await this.getDistricUsers()
    this.formPoint.city_company_item_id = null
    this.formPoint.collector_id = null
    this.formPoint.value = 0
    this.formPoint.status = 1
    this.formPoint.role = null
    this.formPoint.cep = null
    this.formPoint.point_name = null
    this.formPoint.street = null
    this.formPoint.number = null
    this.formPoint.complement = null
    this.formPoint.district = null
    this.formPoint.city = null
    this.formPoint.city_id = null
    this.formPoint.province = null
    this.formPoint.province_id = null
  },
  computed: {
    valid () {
      const form = this.form

      if (!form.collector_id || (form.value === null || form.value === '') || !form.minimal_amount) {
        return false
      }

      return true
    },
    validPoint () {
      const form = this.formPoint
      console.log('valida', form)
      if (!form.collector_id || (form.value === null || form.value === '') || !form.city_id || !form.point_name) {
        return false
      }

      return true
    }
  },
  methods: {
    async onSave () {
      if (this.typeAgent === 'coletor-point') {
        if (!this.validPoint) {
          this.saving = false
          return this.alert('validation')
        }
        this.formPoint.city_company_item_id = this.cityCompanyItem
        this.formPoint.role = this.typeAgent
        const response = await this.$http.post('/collectors/materials/users', this.formPoint)
        const mesage = this.editing ? 'Coletor editado com sucesso.' : 'Coletor adicionado com sucesso.'
        this.alert(response.status ? 'success' : 'error', mesage)
        if (response.status) {
          this.$emit('change-modal', false)
          this.saving = false
          this.form = defaultForm
        } else {
          this.saving = false
        }
      } else {
        if (!this.valid) {
          this.saving = false
          return this.alert('validation')
        }
        this.form.city_company_item_id = this.cityCompanyItem
        this.form.role = this.typeAgent
        const response = await this.$http.post('/collectors/materials/users', this.form)
        const mesage = this.editing ? 'Coletor editado com sucesso.' : 'Coletor adicionado com sucesso.'
        this.alert(response.status ? 'success' : 'error', mesage)
        if (response.status) {
          this.$emit('change-modal', false)
          this.saving = false
          this.form = defaultForm
        } else {
          this.saving = false
        }
      }

      this.formPoint.city_company_item_id = null
      this.formPoint.collector_id = null
      this.formPoint.value = 0
      this.formPoint.status = 1
      this.formPoint.role = null
      this.formPoint.cep = null
      this.formPoint.point_name = null
      this.formPoint.street = null
      this.formPoint.number = null
      this.formPoint.complement = null
      this.formPoint.district = null
      this.formPoint.city = null
      this.formPoint.city_id = null
      this.formPoint.province = null
      this.formPoint.province_id = null
    },
    async getCollectorUsers () {
      const response = await this.$http.get('/collectors/users')
      // this.collectorUsers = response.data.data.filter(value => value.company_roles[0].role.slug === this.typeAgent || value.company_roles[1].role.slug === this.typeAgent || value.company_roles[2].role.slug === this.typeAgent)
      const collector = this.typeAgent
      this.collectorUsers = response.data.data.filter(function (value) {
        if (value.company_roles[0] !== undefined) {
          if (value.company_roles[0].role.slug === collector) {
            return value
          }
        }

        if (value.company_roles[1] !== undefined) {
          if (value.company_roles[1].role.slug === collector) {
            return value
          }
        }

        if (value.company_roles[2] !== undefined) {
          if (value.company_roles[2].role.slug === collector) {
            return value
          }
        }
      })
    },
    async getDistricUsers () {
      const response = await this.$http.get('/district?city_id=' + this.cityId)
      this.neighborhoods = response.data
    },
    alert (type = 'success', text = 'Verifique todos os campos.') {
      swal({
        title: type === 'success' ? 'Sucesso!' : 'Ooops!',
        icon: type === 'success' ? 'success' : 'error',
        text,
        button: type === 'success' ? null : 'Ok',
        timer: type === 'success' ? 2500 : 6000
      })
    }
  },
  watch: {
    async 'formPoint.cep' (val) {
      if (!val || val.length < 8 || this.isEditing) return
      const response = await axios.get('/cep?cep=' + val)
      if (response.data.city_id !== this.cityId) {
        swal({
          title: 'Erro!',
          icon: 'error',
          text: 'O CEP de cadastro do ponto de coleta deve ser da cidade de ' + this.city,
          button: 'Ok',
          timer: 6000
        })
      } else {
        if(response.data.logradouro === '') {
          this.cepNotFound = false
        }

        this.formPoint.cep = response.data.cep
        this.formPoint.district = response.data.bairro
        this.formPoint.street = response.data.logradouro
        this.formPoint.province_id = response.data.province_id
        this.formPoint.city_id = response.data.city_id
        this.formPoint.city = response.data.localidade
        this.formPoint.province = response.data.uf
      }
    },
    collectorUsers () {
      this.loadingCollectorUsers = false
    },
    typeAgent () {
      this.getCollectorUsers()
    },
    neighborhoods () {
      this.loadingNeighborhoods = false
    },
    editing () {
      console.log('editing', this.editing)
      if (this.editing.role === 'coletor-point') {
        this.formPoint.city_company_item_id = this.editing.city_company_item_id
        this.formPoint.collector_id = this.editing.collector_id
        this.formPoint.value = this.editing.value
        this.formPoint.status = 1
        this.formPoint.role = this.editing.role
        this.formPoint.cep = this.editing.point.cep
        this.formPoint.point_name = this.editing.point.point_name
        this.formPoint.street = this.editing.point.street
        this.formPoint.number = this.editing.point.number
        this.formPoint.complement = this.editing.point.complement
        this.formPoint.district = this.editing.point.district
        this.formPoint.city = this.editing.point.city.name
        this.formPoint.city_id = this.editing.point.city_id
        this.formPoint.province = this.editing.point.province.initials
        this.formPoint.province_id = this.editing.point.province_id
      } else if(this.editing.id === null) {
        this.formPoint.city_company_item_id = null
        this.formPoint.collector_id = null
        this.formPoint.value = 0
        this.formPoint.status = 1
        this.formPoint.role = null
        this.formPoint.cep = null
        this.formPoint.point_name = null
        this.formPoint.street = null
        this.formPoint.number = null
        this.formPoint.complement = null
        this.formPoint.district = null
        this.formPoint.city = null
        this.formPoint.city_id = null
        this.formPoint.province = null
        this.formPoint.province_id = null
      } else {
        this.form.id = this.editing.id
        this.form.city_company_item_id = this.editing.city_company_item_id
        this.form.collector_id = this.editing.collector.id
        this.form.district_id = this.editing.district_id
        this.form.value = this.editing.value
        this.form.minimal_amount = this.editing.ammount
      }
    }
  }
}
</script>

<style scoped>

</style>
